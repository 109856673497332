<template>
  <div>
    <div v-if="hasSerie === true">
      <b-modal id="create__person" title="Crear persona">
        <PersonCreate />
      </b-modal>

      <!-- CREDIT MODAL -->
      <b-modal id="create__credit" title="Seleccionar periodos" size="xl">
        <div class="row">
          <b-form-group class="col-md-3" label="Monto a crédito restante">
            <b-form-input v-model="totalCreditLeft"></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-3" label="Monto">
            <b-form-input v-model="creditModalAmount"></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-3" label="Fecha">
            <b-form-datepicker v-model="creditModalDate"></b-form-datepicker>
          </b-form-group>
          <b-form-group class="col-md-3" label="-">
            <b-button variant="primary" @click="addCreditPeriod"
              >Seleccionar
            </b-button>
          </b-form-group>
        </div>
        <div class="row">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-tr>
                <b-th>Periodo</b-th>
                <b-th>Cantidad</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(i, idx) in form.credit_payments" :key="idx">
                <b-td>{{ i.date }}</b-td>
                <b-td>{{ i.amount.toFixed(2) }}</b-td>
                <b-td
                  ><a href="" @click="deleteModalTableCredit($event, idx)"
                    >Eliminar</a
                  ></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-modal>

      <!-- ADD PRODUCT TO THE MAIN PAGE -->
      <b-modal
        id="modal-search-products"
        size="xl"
        title="Agregar producto o servicio"
      >
        <div class="row mb-3">
          <b-form-group class="col-md-4" label="Producto/servicio">
            <multiselect
              v-model="bufferProduct.product"
              :multiple="false"
              track-by="id"
              :custom-label="canonicalNameWithBarcode"
              :options="items"
              :searchable="true"
              :allow-empty="true"
              placeholder="Busque por nombre o cód. barras"
              @search-change="searchItem"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                >{{ option.barcode }} - {{ option.barcode }}
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group class="col-md-3" label="Almacén">
            <b-form-select
              v-model="bufferProduct.warehouse"
              :options="warehouses"
              text-field="name"
              value-field="id"
              size="sm"
              @input="getItemWarehouse()"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Stock" class="col-md-3">
            <b-form-input
              :readonly="true"
              v-model="stock"
              size="sm"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Tipo af. IGV" class="col-md-2">
            <b-form-input :disabled="true" size="sm"></b-form-input>
          </b-form-group>
        </div>
        <div class="row">
          <b-form-group label="Cantidad" class="col-md-4">
            <b-form-input
              type="number"
              step="0.01"
              v-model="bufferProduct.quantity"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Precio unitario" class="col-md-4">
            <b-form-input
              v-model="bufferProduct.product.sale_unit_price"
              type="number"
              step="0.01"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Total" class="col-md-4">
            <b-form-input
              :value="
                bufferProduct.quantity * bufferProduct.product.sale_unit_price
              "
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="row">
          <b-button
            @click="addItem"
            variant="primary"
            :disabled="
              !(
                this.utils.isNotNullOrEmpty(bufferProduct.product.id) &&
                parseFloat(bufferProduct.quantity) > 0 &&
                parseFloat(bufferProduct.product.sale_unit_price) > 0 &&
                this.stock > 0
              )
            "
          >
            Agregar
          </b-button>
        </div>
      </b-modal>

      <!-- DESPATCH MODAL -->
      <b-modal id="modal__despatch" title="Datos de guía remisión" size="xl">
        <div class="row">
          <b-form-group class="col-md-6" label="Vehículo">
            <multiselect
              v-model="form.vehicle"
              :multiple="false"
              track-by="id"
              label="plate"
              :options="vehicles"
              :searchable="true"
              :allow-empty="false"
              @search-change="searchVehicle"
              placeholder="Buscar vehículo"
            >
            </multiselect>
          </b-form-group>
          <b-form-group class="col-md-6" label="Conductor">
            <multiselect
              v-model="form.driver"
              :multiple="false"
              track-by="id"
              label="full_name"
              :options="drivers"
              :searchable="true"
              :allow-empty="false"
              @search-change="searchVehicle"
              placeholder="Buscar vehículo"
            >
            </multiselect>
          </b-form-group>
        </div>
        <div class="row">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-tr>
                <b-th>Periodo</b-th>
                <b-th>Cantidad</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(i, idx) in form.credit_payments" :key="idx">
                <b-td>{{ i.date }}</b-td>
                <b-td>{{ i.amount.toFixed(2) }}</b-td>
                <b-td
                  ><a href="" @click="deleteModalTableCredit($event, idx)"
                    >Eliminar</a
                  ></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-modal>

      <div class="row">
        <div class="col-md-12">
          <b-card>
            <b-card-text>
              <div>
                <b-form>
                  <div class="row">
                    <b-form-group
                      class="col-md-4 mb-2"
                      label="Tipo doc."
                      label-size="sm"
                    >
                      <b-form-select
                        v-model="form.document_type"
                        value-field="id"
                        text-field="name"
                        :options="documentTypes"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Cliente"
                      class="col-md-6"
                      label-size="sm"
                    >
                      <span class="label__create" @click="openModalNewPerson"
                        >[+nuevo]</span
                      >
                      <multiselect
                        v-model="form.customer"
                        :multiple="false"
                        track-by="id"
                        label="doc_number"
                        :options="customers"
                        :searchable="true"
                        :allow-empty="true"
                        @search-change="searchCustomer"
                        placeholder="Ingrese DNI/RUC/CE/PASS"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          >{{ option.doc_number }} -
                          {{ formatCustomer(option) }}
                        </template>
                      </multiselect>
                    </b-form-group>
                    <div class="col-md-2">
                      <b-form-checkbox
                        v-model="form.delivery_pendant"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Es delivery
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="form.is_invoice_sale"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Es venta fact.
                      </b-form-checkbox>
                      <b-input-group class="mt-3" v-if="form.is_invoice_sale">
                        <template #append>
                          <b-input-group-text
                            ><strong>%</strong></b-input-group-text
                          >
                        </template>
                        <b-form-input
                          type="number"
                          v-model="form.invoice_sale_percent"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>

                  <div class="row">
                    <b-table-simple hover small>
                      <b-thead>
                        <b-tr>
                          <b-th>Descripción</b-th>
                          <b-th>Unidad</b-th>
                          <b-th>Cantidad</b-th>
                          <b-th>Precio</b-th>
                          <b-th>Total</b-th>
                          <b-th>Acciones</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(item, index) in form.items" :key="index">
                          <b-td>
                            {{ item.canonical_name }}
                          </b-td>
                          <b-td>
                            {{ item.unit_type }}
                          </b-td>
                          <b-td>
                            <b-form-input
                              @keyup="updateItemPrice(index, 'quantity')"
                              v-model="form.items[index].quantity"
                              size="sm"
                            ></b-form-input>
                          </b-td>
                          <b-td>
                            <b-form-input
                              @keyup="updateItemPrice(index, 'price')"
                              v-model="form.items[index].price"
                              size="sm"
                            ></b-form-input>
                          </b-td>
                          <b-td>
                            <b-form-input
                              @keyup="updateItemPrice(index, 'total')"
                              v-model="form.items[index].total"
                              size="sm"
                            ></b-form-input>
                          </b-td>
                          <b-td>
                            <b-button
                              size="sm"
                              variant="danger"
                              class="mb-2"
                              @click="removeItem(index)"
                            >
                              <b-icon icon="trash" aria-label="Help"></b-icon>
                            </b-button>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div class="row mb-5">
                    <b-button variant="primary" v-b-modal.modal-search-products
                      >Agregar producto
                    </b-button>
                  </div>
                  <div class="row">
                    <b-form-group label="Método de pago" class="col-md-4 mb-0">
                    </b-form-group>
                    <b-form-group label="Monto" class="col-md-2 mb-0">
                    </b-form-group>
                    <b-form-group label="Adicional" class="col-md-2 mb-0">
                    </b-form-group>
                    <b-form-group label="Acciones" class="col-md-4 mb-0">
                    </b-form-group>
                  </div>
                  <div
                    class="row"
                    v-for="(payment_method, index) in form.payment_methods"
                    :key="index"
                  >
                    <b-form-group class="col-md-4 mb-2">
                      <b-form-select
                        v-model="form.payment_methods[index].payment_method"
                        value-field="id"
                        text-field="name"
                        :options="paymentMethods"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group class="col-md-2 mb-2">
                      <b-form-input
                        type="number"
                        v-model="form.payment_methods[index].amount"
                        step="0.01"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-2 mb-2">
                      <b-form-input
                        type="text"
                        v-model="form.payment_methods[index].info"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4 mb-2">
                      <b-button
                        size="sm"
                        variant="danger"
                        v-if="form.payment_methods.length > 1"
                        class="mr-2"
                        @click="removePaymentMethod(index)"
                      >
                        <b-icon icon="trash" aria-label="Delete"></b-icon>
                      </b-button>
                      <b-button
                        size="sm"
                        class="mr-2"
                        variant="success"
                        @click="addPaymentMethod"
                      >
                        <b-icon
                          icon="plus"
                          aria-label="Añadir entrada"
                        ></b-icon>
                      </b-button>
                      <b-button
                        v-if="
                          form.payment_methods[index].payment_method ===
                          'CREDIT'
                        "
                        size="sm"
                        variant="success"
                        @click="openModalCredit"
                        title="Seleccionar periodos de crédito"
                      >
                        <b-icon
                          icon="clock-fill"
                          aria-label="Añadir entrada"
                        ></b-icon>
                      </b-button>
                    </b-form-group>
                  </div>
                </b-form>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <portal to="footer" id="portal__footer">
        <div class="row row-cards row-deck">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body p-3 text-center">
                <div class="text-left text-green">{{ subTotalForCard[1] }}</div>
                <div class="h1 m-0">
                  {{ subTotalForCard[0].toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body p-3 text-center">
                <div class="text-left text-green">S/</div>
                <div class="h1 m-0">
                  {{ total.toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 btn-group-vertical">
            <button class="btn btn-success" @click="sell()">Vender</button>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";
import PersonCreate from "@/view/pages/person/PersonCreate";

export default {
  name: "SalesCreate", // 4274b912-dec8-4f48-b932-af8189d6ee3e
  components: { PersonCreate },
  data() {
    return {
      hasSerie: false,
      bufferProduct: {
        product: { sale_unit_price: 0.0 },
        quantity: 1,
        total: 0.0,
        warehouse: null,
      },
      creditModalAmount: 0.0,
      creditModalDate: "",
      customers: [],
      disabledAddProductButtonModal: true,
      documentTypes: [
        { id: "PE", name: "PEDIDO" },
        { id: "VA", name: "VALE" },
        { id: "01", name: "FACTURA" },
        { id: "03", name: "BOLETA" },
      ],
      drivers: [],
      form: {
        customer: null,
        discount: 0.0,
        document_type: "03",
        driver: null,
        delivery_pendant: false,
        invoice_sale_percent: 0,
        is_invoice_sale: false,
        items: [],
        payment_methods: [{ payment_method: "CASH", amount: 0.0, info: null }],
        total: 0.0,
        credit_payments: [],
        vehicle: null,
      },
      items: [],
      paymentMethods: [],
      q: null,
      stock: 0,

      utils: Utils,
      vehicles: [],
      warehouses: [],
    };
  },
  methods: {
    addPaymentMethod() {
      if (this.form.payment_methods.length < this.paymentMethods.length) {
        this.form.payment_methods.push({
          payment_method: null,
          amount: 0.0,
          info: null,
        });
      }
    },
    addCreditPeriod() {
      if (
        this.totalCreditLeft <= 0 ||
        this.totalCreditLeft - parseFloat(this.creditModalAmount) < 0
      ) {
        this.$errorToast("Ingresa un monto menor");
        return;
      }
      this.form.credit_payments.push({
        date: this.creditModalDate,
        amount: parseFloat(this.creditModalAmount),
      });
    },
    addItem() {
      const bP = { ...this.bufferProduct };
      const objP = {
        id: bP.product.id,
        canonical_name: bP.product.canonical_name,
        unit_type: bP.product.unit_type,
        price: bP.product.sale_unit_price,
        quantity: bP.quantity,
        warehouse: bP.warehouse,
        total: bP.quantity * bP.product.sale_unit_price,
      };
      if (this.form.items.findIndex((x) => x.id === objP.id) !== -1) {
        this.cleanModal();
        return false;
      }
      this.form.items.push(objP);
      this.cleanModal();
    },
    canonicalNameWithBarcode({ canonical_name, barcode }) {
      return `${canonical_name} - ${barcode}`;
    },
    checkIfHasSerie() {
      if (localStorage.getItem("pos_key") === null) {
        window.location.href = "/";
      }
      ApiService.get(
        "core/machine-id/identifier",
        `?identifier=${localStorage.pos_key}`
      )
        .then(() => {
          this.hasSerie = true;
        })
        .catch(() => {
          window.location.href = "/";
        });
    },
    cleanModal() {
      this.bufferProduct = {
        product: { sale_unit_price: 0.0 },
        quantity: 1,
        total: 0.0,
        warehouse: null,
      };
      this.stock = 0;
    },
    deleteModalTableCredit($event, index) {
      $event.preventDefault();
      this.form.credit_payments.splice(index, 1);
    },
    formatCustomer(customer) {
      let customerName = "";
      if (Utils.isNotNullOrEmpty(customer.first_name)) {
        customerName += customer.first_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name)) {
        customerName += customer.last_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name_2)) {
        customerName += customer.last_name_2;
      }
      return customerName;
    },
    getItemWarehouse() {
      ApiService.get(
        "core/item-warehouse",
        `?page_size=10000&page=1&item=${this.bufferProduct.product.id}&warehouse=${this.bufferProduct.warehouse}`
      ).then((x) => {
        const res = x.data.results;
        if (res.length > 0) {
          this.stock = res[0].stock;
        }
      });
    },
    getPaymentMethods() {
      ApiService.get("constants", "?name=PAYMENT_METHODS").then((response) => {
        this.paymentMethods = response.data;
      });
    },
    getWarehouses() {
      ApiService.get("core/warehouse", "?page_size=100000&page=1").then(
        (res) => {
          this.warehouses = res.data.results;
        }
      );
    },
    isValidForm() {
      const form = this.form;
      let isValid = true;
      let error = "";

      // Check if there is duplicated payment method
      let s = new Set(form.payment_methods.map((x) => x.payment_method));
      if (s.size !== form.payment_methods.length) {
        error += "Hay métodos de pago duplicados, por favor, corrija | ";
        isValid = false;
      }
      if (this.total <= 0) {
        error += "El total del comprobante no puede ser 0 | ";
        isValid = false;
      }
      if (
        this.form.payment_methods
          .map((x) => parseFloat(x.amount))
          .reduce((a, b) => a + b, 0) !== this.total
      ) {
        error += "Los montos de pago registrados no coinciden | ";
        isValid = false;
      }
      if (!Utils.isNotNullOrEmpty(form.customer)) {
        error += "Elija un cliente válido | ";
        isValid = false;
      }
      if (form.items.length < 1) {
        error += "Elija al menos un item para vender | ";
        isValid = false;
      }
      if (form.payment_methods.length > this.paymentMethods.length) {
        error += "Has repetido algún método de pago | ";
        isValid = false;
      }

      // CHECK IS THERE IS A CREDIT PAYMENT AND THE SUM IS VALID
      const existsCredit = this.form.payment_methods.find(
        (x) => x.payment_method === "CREDIT"
      );
      if (existsCredit !== undefined && this.totalCreditLeft < 0) {
        error += "El monto de las cuotas al crédito no es válido | ";
        isValid = false;
      }

      if (isValid === false) {
        this.$errorToast(error);
      }

      return isValid;
    },
    openModalCredit() {
      this.$bvModal.show("create__credit");
    },
    openModalDespatch() {
      if (this.form.delivery_pendant === true) {
        this.$bvModal.show("modal__despatch");
      }
    },
    openModalNewPerson() {
      this.$bvModal.show("create__person");
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    removePaymentMethod(index) {
      this.form.payment_methods.splice(index, 1);
    },
    searchCustomer(query) {
      if (query.length <= 5) {
        return false;
      }
      ApiService.get(
        "core/person",
        `?page=1&page_size=100&doc_number=${query}`
      ).then((res) => {
        this.customers = res.data.results;
      });
    },
    searchItem(query) {
      if (query.length <= 4) {
        return false;
      }
      this.cleanModal();
      ApiService.get(
        "core/item/combined",
        `?page=1&page_size=10&barcode=${query}&canonical_name=${query}`
      ).then((res) => {
        this.items = res.data.results;
      });
    },
    searchVehicle(query) {
      if (query.length <= 3) {
        return false;
      }
      ApiService.get(
        "core/vehicle",
        `?page=1&page_size=10000&plate=${query}`
      ).then((res) => {
        this.vehicles = res.data.results;
      });
    },
    sell() {
      if (!this.isValidForm()) {
        return false;
      }

      let form = { ...this.form };
      form.pos_key = localStorage.pos_key;
      form.customer = form.customer.id;
      form = ApiService.post("core/invoice/", form).catch((error) => {
        this.$errorToast(error);
      });
    },
    updateItemPrice(idx, inputType = "quantity") {
      if (inputType === "quantity" || inputType === "price") {
        this.form.items[idx].total =
          this.form.items[idx].quantity * this.form.items[idx].price;
      }
      if (inputType === "total") {
        this.form.items[idx].quantity =
          this.form.items[idx].total / this.form.items[idx].price;
      }
    },
  },
  mounted() {
    this.checkIfHasSerie();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Venta" }]);
    this.getPaymentMethods();
    this.getWarehouses();
  },
  watch: {
    // When value changes, pull the result to payment method
    total(newValue, oldValue) {
      console.info(oldValue);
      this.form.payment_methods = [
        {
          payment_method: "CASH",
          amount: newValue,
          info: null,
        },
      ];
    },
  },
  computed: {
    total: function () {
      return (
        this.form.items
          .map((x) => parseFloat(x.price) * parseFloat(x.quantity))
          .reduce((a, b) => a + b, 0) - parseFloat(this.form.discount)
      );
    },
    totalCredit: function () {
      const el = this.form.payment_methods.find(
        (x) => x.payment_method === "CREDIT"
      );
      return el === undefined ? 0.0 : parseFloat(el.amount);
    },
    totalCreditLeft: function () {
      return (
        this.totalCredit -
        this.form.credit_payments
          .map((x) => parseFloat(x.amount))
          .reduce((a, b) => a + b, 0)
      );
    },
    paymentMethodsTotal: function () {
      return this.form.payment_methods
        .map((x) => parseFloat(x.amount))
        .reduce((a, b) => a + b, 0);
    },
    subTotalForCard: function () {
      let q = this.paymentMethodsTotal - this.total;
      let text = "-";
      text = q < 0 ? "Falta" : "Vuelto";
      return [q, text];
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-right {
  margin-left: auto;
}

.vue-portal-target {
  width: 100% !important;
}

.label__create {
  font-weight: bold;
  float: right;
  margin-top: -25px;
  color: #3699ff;
  cursor: pointer;
}
</style>
